import * as React from "react"
import Home from "../components/Home"

import Layout from "../components/layout"
import Seo from "../components/seo"
import "./index.scss"


const IndexPage = () => (
  <Layout>
    <Seo title="Zaru Art" />
    <Home />
  </Layout>
)

export default IndexPage
