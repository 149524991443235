import React from 'react';
import StyleSection from './StyleSection';
import BiographySection from '../Biography';
import * as styles from './home.module.scss';
import logo from './images/YuliaZaruLogo.svg';

export const Home = (props) => {
    return (
      <section className={styles.homeContainer}>
        <img
          className={styles.homeLogo}
          src={logo}
          alt="Logo"/>
        <h1>YULIA ZARU</h1>
        {/*<h2>Emphasizing patterns of nature, finding magic in the power of NOW.</h2>*/}
        <div className={styles.artistValues}>
          <p>
            Now through <span>May 30</span> all the proceeds from sales will go to <a href="https://www.ukrainecrisisfund.com/">Ukraine Crisis Fund.</a>
            <br/>
            Click on any style below to choose an artwork
            <br/>
            Email me <a href="mailto:hi@yuliazaru.com">here</a> with your print order details
          </p>
        </div>
        <StyleSection />
        <BiographySection />
      </section>
    );
}
