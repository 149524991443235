// extracted by mini-css-extract-plugin
export var biographySection = "biography-module--biographySection--AmCam";
export var imagesContainer = "biography-module--imagesContainer--+7XbE";
export var topRow = "biography-module--topRow--DTG7M";
export var imageContainer = "biography-module--imageContainer--Xv1CA";
export var experienceLarge = "biography-module--experienceLarge--ZSGVB";
export var logoContainerLarge = "biography-module--logoContainerLarge--kvGka";
export var experience = "biography-module--experience--BzLzD";
export var logosContainer = "biography-module--logosContainer--rTM4G";
export var imagesWrapper = "biography-module--imagesWrapper--LTYvx";
export var bottomRow = "biography-module--bottomRow--pTBmX";