import React, { Component } from 'react';

import * as styles from './biography.module.scss';
import autoGraphCollection from './images/logos/autograph_collection.png'
import marriott from './images/logos/marriott.png'
import edwin from './images/logos/edwin.png'
import designHotels from './images/logos/design_hotels.png'
import dwell from './images/logos/dwell.png'

class Biography extends Component {
  render() {
    return (
      <section className={styles.biographySection}>
        <div className={styles.logosContainer}>
          <div>
            <img src={autoGraphCollection} alt="Autograph Collection"/>
            <img src={marriott} alt="Marriott Logo"/>
          </div>
          <div>
            <img src={edwin} alt="Edwin Logo"/>
            <img src={designHotels} alt="Design Hotels Logo"/>
            <img src={dwell} alt="Dwell Logo"/>
          </div>
        </div>
        <div className={styles.imagesWrapper}>
          <div className={styles.imagesContainer}>
            <div className={styles.topRow}>
              <div/>
              <div/>
            </div>
            <div className={styles.bottomRow}>
              <div/>
              <div/>
            </div>
          </div>
          <div className={styles.imageContainer}>
            <div/>
          </div>
          <div className={styles.experienceLarge}>
            <h5>ARTIST'S BIO:</h5>
            <p>GRADUATED FROM MOSCOW UNIVERSITY OF PRINTING ARTS</p>
            <p>LED CREATIVE DEVELOPMENTS FOR FORTUNE 500 COMPANIES</p>
            <p>ARTS BUILD LEADERSHIP CLASS ALUMNI</p>
            <p>CULTURAL AMBASSADOR FOR THE CITY OF CHATTANOOGA</p>
          </div>
        </div>
        <div className={styles.experience}>
          <h5>ARTIST'S BIO</h5>
          <p>GRADUATED FROM MOSCOW UNIVERSITY OF PRINTING ARTS</p>
          <p>LED CREATIVE DEVELOPMENTS FOR 500 COMPANIES</p>
          <p>ARTS BUILD LEADERSHIP CLASS ALUMNI</p>
          <p>CULTURAL AMBASSADOR FOR THE CITY OF CHATTANOOGA</p>
        </div>
        <div className={styles.logoContainerLarge}>
          <img src={autoGraphCollection} alt="Autograph Collection"/>
          <img src={edwin} alt="Edwin Logo"/>
          <img src={designHotels} alt="Design Hotels Logo"/>
          <img src={marriott} alt="Marriott Logo"/>
          <img src={dwell} alt="Dwell Logo"/>
        </div>
      </section>
    );
  }
}

export default Biography;
