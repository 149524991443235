import React from 'react';
import { Link } from 'gatsby';
import * as styles from './art_styles.module.scss';

import abstractObsession from './images/abstract_obsession.png';
import boxoLuxe from './images/boxo_luxe.png';
import sophisticatedElegance from './images/sophisticated_elegance.png';
import europeanChic from './images/european_chic.png';
import BWSeduction from './images/black_white_seduction.png';
import UrbanAd from './images/urban_adventures.png';

class StyleSection extends React.Component {
  render() {
    return (
      <div className={styles.styleContainer}>
        <h3>CHOOSE YOUR STYLE</h3>
        <div className={styles.imageRow}>
          <div className={styles.subStyle} onClick={() => window.location = "/art-styles/european-chic" }>
            <p>European Chic</p>
            <Link to="/art-styles/european-chic">
              <img src={europeanChic} alt=""/>
            </Link>
          </div>
          <div className={styles.subStyle} onClick={() => window.location = "/art-styles/abstract-obsession" }>
            <p>Abstract Obsession</p>
            <Link to="/art-styles/abstract-obsession"><img src={abstractObsession} alt=""/></Link>
          </div>
          <div className={styles.subStyle} onClick={() => window.location = "/art-styles/sophisticated-elegance" }>
            <p>Sophisticated Elegance</p>
            <Link to="/art-styles/sophisticated-elegance"><img src={sophisticatedElegance} alt=""/></Link>
          </div>
        </div>
        <div className={styles.imageRow}>
          <div className={styles.subStyle} onClick={() => window.location = "/art-styles/boho-luxe" }>
            <p>Boxo Luxe</p>
            <Link to="/art-styles/boho-luxe"><img src={boxoLuxe} alt=""/></Link>
          </div>
          <div className={styles.subStyle} onClick={() => window.location = "/art-styles/coastal-hollywood" }>
            <p>Coastal Hollywood</p>
            <Link to="/art-styles/coastal-hollywood"><img src={BWSeduction} alt=""/></Link>
          </div>
          <div className={styles.subStyle} onClick={() => window.location = "/art-styles/urban-adventures" }>
            <p>Urban Adventures</p>
            <Link to="/art-styles/urban-adventures"><img src={UrbanAd} alt=""/></Link>
          </div>
        </div>
        <h4>
          What are the main goals and biggest challenges when you work on the interior of hotels and restaurants?
        </h4>
        <div className={styles.artInfoContainer}>
          <div className={styles.artInfo}>
            <p>
              Whether you are creating a relaxing and calm or vivid and inspiring atmosphere, the main goal is always
              to deliver an unforgettable visitor experience. Creating a world of emotions and making visitors remember
              your venue forever is the number one priority you must uphold as an interior expert.
            </p>
            <p>
              As an atmospheric designer, Yulia Zaru understands the importance of art as an initial part of every
              interior. Her artistic approach is based on deep knowledge of visual elements and compositions that
              appeal to the <strong>psychology of memory</strong> and <strong>ancient philosophies</strong> of color evocation. She constantly researches
              global cultural shifts and worldwide interior trends that are reflected in her art works. This all helps
              to provide an unforgettable visual experience filled with emotions.
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default StyleSection;
